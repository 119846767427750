import React from "react";

// TypeScript type for the props of the Placeholder component
interface PlaceholderProps {
  className?: string;
}

const Placeholder: React.FC<PlaceholderProps> = ({ className }) => {
  
  return <div className={["inline-block bg-white bg-opacity-10 animate-pulse min-w-[80px] h-[0.8em]", className].join(" ")}>
  </div>
 
};

export default Placeholder;
