import React, { useState } from "react";
import Page from "../../skeletons/Page";
import Panel from "../../elements/atoms/Panel";

import Form from "../../elements/atoms/form/Form";
import { FormProvider } from "../../elements/atoms/form/FormContext";
import InputHashcatMode from "../../elements/atoms/form/input/InputHashcatMode";
import InputTextArea from "../../elements/atoms/form/input/InputTextArea";
import InputFile from "../../elements/atoms/form/input/InputFile";
import Button from "../../elements/atoms/Button";
import * as yup from "yup";
import Highlight from "../../elements/atoms/Highlight";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../api/useAxios";

function NewBounty() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const axios = useAxios()
  const navigate = useNavigate()

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      setError(null);
      const resp = await axios.post("/v1/bounty/new", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/bounty/" +  resp.data.bountyId)
    } catch (e: any) {
      setError(e.response.data.message);
    }
    setLoading(false);
  };

  const validationSchema = yup
    .object()
    .shape({
      mode: yup.string().required("Hashcat mode is required"),
      hashesText: yup.string().nullable(),
      hashesFile: yup.mixed().nullable(),
    })
    .test(
      "text-or-file-validation",
      'You must provide either "text" or "file", but not both.',
      function (values) {
        const { hashesText, hashesFile } = values;

        const hasText = Boolean(hashesText && hashesText.trim());
        const hasFile = Boolean(hashesFile);

        if (hasText && hasFile) {
          return new yup.ValidationError([
            new yup.ValidationError(
              "You must fill in only file or text, not both",
              null,
              "hashesText"
            ),
            new yup.ValidationError(
              "You must fill in only file or text, not both",
              null,
              "hashesFile"
            ),
          ]);
        }
        if (!hasText && !hasFile) {
          return new yup.ValidationError([
            new yup.ValidationError(
              "You must fill at least file or text",
              null,
              "hashesText"
            ),
            new yup.ValidationError(
              "You must fill at least file or text",
              null,
              "hashesFile"
            ),
          ]);
        }

        return true;
      }
    );

  return (
    <Page>
      <div className="text-center w-full">
        <FormProvider validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <Panel className="m-4 inline-block">
              <Highlight className=" text-2xl  font-bold ">
                Create a new bounty
              </Highlight>
              <div className="text-gray-500">
                Select a hash mode and upload your hashes!
              </div>
              <div className="my-4">
                <div className="inline-block max-w-[400px]">
                  <InputHashcatMode name="mode" label="Hashcat Mode" />
                  <InputTextArea className="font-mono" name="hashesText" label="Hashes (as text)" />
                  <InputFile name="hashesFile" label="Hashes (or as a file)" />
                  {error && (
                    <div className="text-red-600 m-4 font-bold">{error}</div>
                  )}
                  {loading && (
                    <div className=" m-4 font-bold"> Registering ... </div>
                  )}
                  <Button className="mt-4"> Create </Button>                  
                </div>
              </div>
            </Panel>
          </Form>
        </FormProvider>
      </div>
    </Page>
  );
}

export default NewBounty;
