import React, { useCallback, useEffect, useState } from "react";
import Page from "../skeletons/Page";
import Panel from "../elements/atoms/Panel";

import Form from "../elements/atoms/form/Form";
import { FormProvider } from "../elements/atoms/form/FormContext";
import Button from "../elements/atoms/Button";
import * as yup from "yup";
import Highlight from "../elements/atoms/Highlight";
import useAxios from "../../api/useAxios";
import InputSelect from "../elements/atoms/form/input/InputSelect";
import InputText from "../elements/atoms/form/input/InputText";
import { useToast } from "../elements/atoms/toast/ToastContext";
import InputFile from "../elements/atoms/form/input/InputFile";

function Test() {

  
  const [job, setJob] = useState<any>()

  const [logs, setLogs] = useState()
  const [pots, setPots] = useState()
  const [status, setStatus] = useState()

  const { addToast } = useToast();
  const handleToastClick = () => {
    addToast('This is a success message!', 'success');
  };
 
  const axios = useAxios()

  setTimeout(() => {
    // handleMultipleClicks()
  },3000)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateLogsAndPots = async () => {
    if(job === null || job === undefined){
      return 
    }
    try {
      const logs = await axios.get(`/v1/job/${job.id}/logs?token=${job.token}`);
      setLogs(logs.data.logs)
      const pots = await axios.get(`/v1/job/${job.id}/pot?token=${job.token}`);
      setPots(pots.data)
      const status = await axios.get(`/v1/job/${job.id}/status?token=${job.token}`);
      setStatus(status.data.status)
    } catch (e:any) {

    }
  }

  const startJob = useCallback(() => {
    if(job === null || job === undefined){
      return 
    }
    (async function() {
      await axios.get(`/v1/job/${job.id}/start?token=${job.token}`);
    })()
  }, [axios, job])

  const finishJob = useCallback(() => {
    if(job === null || job === undefined){
      return 
    }
    (async function() {
      await axios.get(`/v1/job/${job.id}/finish?token=${job.token}`);
    })()
  }, [axios, job])

  useEffect(() => {
    const intervalId = setInterval(() => {
       updateLogsAndPots()
    }, 1000)

    return () => {
      clearInterval(intervalId);
    };
  }, [updateLogsAndPots]); 

  const onSubmit = async (values: any) => {
    try {
      const job = await axios.post("/v1/compute/createJob", values);
      setJob(job.data)
    } catch (e:any) {

    }  };
 
  const options = [
    {value: "EXTREME", label: "EXTREME"},
    {value: "HIGH", label: "HIGH"},
    {value: "MEDIUM", label: "MEDIUM"},
    {value: "ECO", label: "ECO"},
  ]
 
  function handleCarriageReturn(str: string) {
    // Split the input into lines, and use the carriage return character to overwrite the line
    const lines = str.split('\n');
    const result = lines.map(line => {
      // Split by carriage returns, and keep only the final part
      const parts = line.split('\r');
      return parts[parts.length - 1]; // Take the last part after the last \r
    });
  
    return result.join('\n');
  }
  
  const validationSchema = yup.object().shape({
    mode: yup.string().required("Mode is required"),
  });

  return (
    <Page>
      <div className="text-center w-full">
      <Button onClick={handleToastClick}>Show Toast</Button>

        <FormProvider validationSchema={validationSchema}   onSubmit={onSubmit}>
          <Form>
            <Panel className="m-4  w-[600px] inline-block">
              <Highlight className=" text-2xl  font-bold ">
                TEST
              </Highlight>

              <div className="text-gray-500">
                test
              </div>
              <div>
              </div>
              <div className="my-4">
                <div className=" ">
                  <InputSelect options={options} name="mode" label="mode"/>
                  <InputText defaultValue="-m 0 -a 3 hashlist --increment '?l?l?l?l?l?l?l'" name="args" label="args"/>
                  <InputFile name="f" label="file"/>
                  <Button className="mt-4"> Create Job </Button>       
                  <hr/>
                  {job && (
                    <Button className="mt-4" preventDefault onClick={startJob}> Start Job </Button>       
                  )}
                  {job && (
                    <Button className="mt-4" preventDefault onClick={finishJob}> finish Job </Button>       
                  )}
                  <div>
                    Status: {status}
                  </div>
                  <div className="grid grid-cols-2 w-full">
                  <pre className="p-4 bg-black  m-4 bg-opacity-60 text-left text-[6px] overflow-x-scroll rounded my-5">
                    {logs && handleCarriageReturn(logs)}
                   </pre>           
                   <pre className="p-4 bg-black  m-4 bg-opacity-60 text-left text-[8px] overflow-x-scroll rounded my-5">
                    {pots && handleCarriageReturn(pots)}
                   </pre>           
                  </div>

                </div>
              </div>
            </Panel>
          </Form>
        </FormProvider>
      </div>
    </Page>
  );
}

export default Test;
