import React from "react";
import InputSelect from "./InputSelect";
import { useWordlist } from "../../provider/WordlistProvider";

const InputWordlist: React.FC<{
  name: string;
  label: string;
  onChange?: (newValue: string) => void;
}> = ({ name, label, onChange }) => {
  const { options } = useWordlist()
  return (
    <InputSelect
      name={name}
      label={label}
      options={options}
      onChange={onChange}
    />
  );
};

export default InputWordlist;
