import { useCallback, useEffect, useState } from "react";
import Page from "../../skeletons/App/Page";
import useAxios from "../../../api/useAxios";
import PanelPlaceholder from "../../elements/atoms/PanelPlaceholder";
import RulesetTile from "./Tiles/RulesetTile";


function Rulesets() {
  const [rulesets, setRulesets] = useState<any[] | null>(null);
  const axios = useAxios();

  const loadRulesets = useCallback(async () => {
    const resp = await axios.get("/v1/ruleset/list");
    setRulesets(resp.data);
  }, [axios]);

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    (async function () {
      setInitialized(true);
      await loadRulesets();
    })();
  });

  return (
    <Page>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {rulesets &&
          rulesets.map((ruleset) => (
            <RulesetTile
              className="m-2"
              key={ruleset.id}
              ruleset={ruleset}
              onUnlock={async () => {
                setRulesets(null)
                await loadRulesets();
              }}
            />
          ))}
        {rulesets === null &&
          Array.from(new Array(12)).map((_, idx) => {
            return <PanelPlaceholder className="m-2" key={idx} />;
          })}          
      </div>
    </Page>
  );
}

export default Rulesets;
