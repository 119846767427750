import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/elements/atoms/auth/AuthContext";
import { ToastProvider } from "./components/elements/atoms/toast/ToastContext";
import { ConfirmProvider } from "./components/elements/atoms/confirm/ConfirmContext";
import { WordlistProvider } from "./components/elements/atoms/provider/WordlistProvider";
import { RulesetProvider } from "./components/elements/atoms/provider/RulesetProvider";
import { HashcatModeProvider } from "./components/elements/atoms/provider/HashcatModesProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ToastProvider>
      <ConfirmProvider>
        <AuthProvider>
          <HashcatModeProvider>
            <WordlistProvider>
              <RulesetProvider>
                <App />
              </RulesetProvider>
            </WordlistProvider>
          </HashcatModeProvider>
        </AuthProvider>
      </ConfirmProvider>
    </ToastProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
