// Form.tsx
import React from 'react';
import { useFormContext } from './FormContext';

interface FormProps {
  children: React.ReactNode;
}

const Form: React.FC<FormProps> = ({ children }) => {
  const { submit } = useFormContext();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submit();
  };

  return (
    <form onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export default Form;
