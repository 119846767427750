import axios from "axios";

export const getApiClient = () => {
    return axios.create({
        baseURL:
          process.env.REACT_APP_ENV === "production"
            ? "https://api.hashrepublic.net"
            : "http://localhost:8080",
        // You can also set default headers if needed
        headers: {
          "Content-Type": "application/json",
          // Add other default headers here
        },
      });
}
