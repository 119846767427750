import Router from "./components/elements/router/Router";

function App() {

  return (
    <div className="approot font-sans">
      <Router></Router>
    </div>
  );
}

export default App;
