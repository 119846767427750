import React from "react";

import Topbar from "./Page/Topbar";
interface PageProps {
  full?: boolean;
  children: React.ReactNode; // children can be any valid React node
}

const Page: React.FC<PageProps> = ({ children, full }) => {
  return (
    <div className="h-screen text-gray-300">
      <div className="flex min-h-full flex-col">
 
      <Topbar></Topbar>
      {full ? (
        <main className="relative flex-1 flex w-full mx-auto p-2 overflow-y-auto">
          {children}
        </main>
      ) : (
        <main className="relative flex-1 flex items-center container mx-auto p-2 overflow-y-auto">
          {children}
        </main>
      )}

      <footer className="relative p-2 text-gray-600">
        <div className="container flex items-center w-full mx-auto">
          <p className="w-full text-left italic">
            {" "}
            Currently in development, don't expect the application to work
          </p>
          <p className="w-full text-right">&copy; 2024 Hash Republic</p>
        </div>
      </footer>
      </div>
    </div>
  );
};

export default Page;
