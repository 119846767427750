import React from "react";

interface HighlightProps {
    className?: string;
  children: React.ReactNode;
}

const Highlight: React.FC<HighlightProps> = (props) => {
  const { children, className} = props;

  return (
    <span className={[
        "inline-block text-transparent bg-clip-text scale-110 origin-center text-black font-bold bg-gradient-to-r from-[#ff4200] to-[#ba00b6] mx-4",
        className
    ].join(' ')}>
      {children}
    </span>
  );
};

export default Highlight;
