import React from "react";
import { NavLink as NL } from "react-router-dom";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string
}

const NavLink: React.FC<NavLinkProps> = (props) => {
  const { children, to, className } = props;

  return (
    <NL
      to={to}
      className={[
        "inline-block opacity-50 hover:opacity-70 active:opacity-100",
        className,
      ].join(" ")}
    >
      {children}
    </NL>
  );
};

export default NavLink;
