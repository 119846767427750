import React, { useState } from "react";
import Page from "../../skeletons/Page";
import Button from "../../elements/atoms/Button";
import InputText from "../../elements/atoms/form/input/InputText";
import { FormProvider } from "../../elements/atoms/form/FormContext";
import Form from "../../elements/atoms/form/Form";
import * as yup from "yup";
import InputPassword from "../../elements/atoms/form/input/InputPassword";
import Highlight from "../../elements/atoms/Highlight";
import { useNavigate } from "react-router-dom";
import Panel from "../../elements/atoms/Panel";
import useAxios from "../../../api/useAxios";

function Register() {
  const axios = useAxios()
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    setError(undefined);
    try {
      await axios.post("/v1/account/register", values);
      navigate("/account/register-success");
    } catch (error: any) {
      setError(error.response.data.error);
    }
    setLoading(false);
  };

  return (
    <Page>
      <div className="text-center w-full">
        <FormProvider validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <Panel className="m-4 inline-block">
              <Highlight className=" text-2xl  font-bold ">Register</Highlight>
              <div className="text-gray-500">
                Don't wait more, and join the Republic!
              </div>
              <div className="my-4">
                <div className="inline-block max-w-[400px]">
                  <InputText label="Username" name="username" />
                  <InputText label="Email" name="email" />
                  <InputPassword label="Password" name="password" />
                  <InputPassword
                    label="Confirm password"
                    name="confirmPassword"
                  />
                  {error && (
                    <div className="text-red-600 m-4 font-bold">{error}</div>
                  )}
                  {loading && (
                    <div className=" m-4 font-bold"> Registering ... </div>
                  )}
                  <Button className="mt-4"> Join </Button>
                </div>
              </div>
            </Panel>
          </Form>
        </FormProvider>
      </div>
    </Page>
  );
}

export default Register;
