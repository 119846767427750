import React, { useCallback } from "react";
import InputSelect from "./InputSelect";
import { useFormContext } from "../FormContext";

const InputAttackMode: React.FC<{
  name: string;
  label: string;
  defaultValue: string;
  onChange?: (newValue: string) => void;
}> = ({ name, label, defaultValue, onChange }) => {
  const options = [
    { label: "0 - Wordlist", value: "0" },
    { label: "1 - Wordlist + Wordlist", value: "1" },
    { label: "3 - Mask", value: "3" },
    { label: "6 - Wordlist + Mask", value: "6" },
    { label: "7 - Mask + Wordlist", value: "7" },
    { label: "HRL - Hash Republic Loop", value: "HRL" },
  ];

  

  const handleOnChange = useCallback((newVal: string) => {
    if(onChange){
      onChange(newVal)
    }
  }, [onChange])


  return (
    <InputSelect
      name={name}
      label={label}
      options={options}
      defaultValue={defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default InputAttackMode;
