import React, { useCallback, useEffect, useRef } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import useAxios from "../../../../api/useAxios";

interface PaypalButtonProps {
  amount: string;
  className?: string;
  onApprove?: () => void;
}

const PaypalButton: React.FC<PaypalButtonProps> = (props) => {
  const { className, amount } = props;

  const amountRef = useRef(amount);

  const axios = useAxios();

  const initialOptions = {
    clientId:
      "AYJj84Nh2l7JL1pNBNImv5BDtWC4u6zsR8uM6oVxs-ko0Lv-uEIKOVMxvnjZVj_krihS9C20j1bo4xPg",
    currency: "USD",
    intent: "capture",
  };

  useEffect(() => {
    amountRef.current = amount
  }, [amount])


  const createOrder = useCallback(async () => {
    try {
      const response = await axios.post(
        "/v1/payment/sandbox/paypal/createOrder",
        {
          amount: amountRef.current,
        }
      );
      return response.data.orderId;
    } catch (error) {
      console.error(error);
    }
  }, [axios]);

  const onApprove = useCallback(
    async (data: any, actions: any) => {
      try {
        const response = await axios.post(
          "/v1/payment/sandbox/paypal/captureOrder",
          {
            orderId: data.orderID,
          }
        );
        const orderData = response.data;

        console.log("Capture result", orderData);
        if (props.onApprove) {
          props.onApprove();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [axios, props]
  );

  return (
    <div className={["w-full inline-block", className].join(" ")}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "horizontal",
            color: "gold",
            label: "paypal",
            tagline: false,
          }}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default PaypalButton;
