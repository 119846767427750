import { useCallback, useEffect, useState } from "react";
import Page from "../../skeletons/App/Page";
import AddHashlistTile from "./Tiles/AddHashlistTile";
import useAxios from "../../../api/useAxios";
import HashlistTile from "./Tiles/HashlistTile";
import Modal from "../../elements/atoms/modal/Modal";
import Button from "../../elements/atoms/Button";
import { useToast } from "../../elements/atoms/toast/ToastContext";
import PanelPlaceholder from "../../elements/atoms/PanelPlaceholder";

function Hashlists() {
  const [isCreateModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [hashlists, setHashlists] = useState<any[] | null>(null);
  const axios = useAxios();
  const { addToast } = useToast();

  const loadHashlists = useCallback(async () => {
    // await new Promise((r) => setTimeout(r, 10000))
    const resp = await axios.get("/v1/hashlist/list");
    setHashlists(resp.data);
  }, [axios]);

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    (async function () {
      setInitialized(true);
      await loadHashlists();
    })();
  });

  return (
    <Page>
      <div className="p-2 border-t border-b border-gray-800 m-4 flex">
        <div className="flex-1"></div>
        <div>
          <Button size="sm" onClick={() => setCreateModalOpened(true)}>
            {" "}
            CREATE{" "}
          </Button>
        </div>
      </div>
      {hashlists && hashlists.length === 0 && <div className="text-center italic p-4">
        It seems you don't have added any hashlist yet, start by creating your first one using the "CREATE" button above.
        </div>}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {hashlists &&
          hashlists.map((hashlist) => {
            return (
              <HashlistTile
                className="m-2"
                key={hashlist.id}
                hashlist={hashlist}
              />
            );
          })}
        {hashlists === null &&
          Array.from(new Array(5)).map((_, idx) => {
            return <PanelPlaceholder className="m-2" key={idx} />;
          })}
      </div>
      <Modal isOpened={isCreateModalOpened} setIsOpened={setCreateModalOpened}>
        <AddHashlistTile
          onAddSuccess={() => {
            loadHashlists();
            setCreateModalOpened(false);
            addToast("Succesfully created hashlist", "success");
          }}
        />
      </Modal>
    </Page>
  );
}

export default Hashlists;
