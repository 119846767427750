// FormContext.tsx
import React, { createContext, useContext, useState } from "react";
import * as yup from "yup";

interface FormContextProps {
  values: Record<string, any>;
  errors: Record<string, string>;
  unsetValue: (field: string) => void;
  setValue: (field: string, value: any) => void;
  setError: (field: string, error: string) => void;
  validate: () => Promise<boolean>;
  submit: () => Promise<void>;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

interface FormProviderProps {
  validationSchema?: yup.ObjectSchema<any>;
  onSubmit?: (values: any) => void;
  children: React.ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({
  children,
  validationSchema,
  onSubmit,
}) => {
  const [values, setValues] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const setValue = (field: string, value: any) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  const unsetValue = (field: string) => {
    setValues((prev) => {
      const { [field]: _, ...rest } = prev; // Extract and ignore the field to be removed
      return rest; // Return the new state object without the removed field
    });
  };

  const setError = (field: string, error: string) => {
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  const validate = async () => {
    if (validationSchema === undefined) {
      return true;
    }
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const fieldErrors: Record<string, string> = {};
        err.inner.forEach((error: yup.ValidationError) => {
          fieldErrors[error.path || ""] = error.message;
        });
        setErrors(fieldErrors);
      }
      return false;
    }
  };

  const submit = async () => {
    if (await validate()) {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  };

  return (
    <FormContext.Provider
      value={{
        values,
        errors,
        setValue,
        setError,
        unsetValue,
        validate,
        submit,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
