// useAxios.js
import { useCallback } from "react";
import useAxios from "./useAxios";
import { useToast } from "../components/elements/atoms/toast/ToastContext";

const useDownload = () => {

    const axios = useAxios()
    const { addToast, delToast } = useToast()
  
    const downloadFile = useCallback(async (
        path: string, 
        filename: string 
    ) => {
        const toastId = addToast("Downloading file ...", "info", 0);
        try {
          const response = await axios.get(path, {
            responseType: "blob", // Important for binary data
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); // Set the desired file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          delToast(toastId)
          addToast("Successfully downloaded file", "success");
        } catch (error) {
          delToast(toastId)
          addToast("Error downloading file", "error");
        }
      }, []);
    
      return downloadFile
      
};

export default useDownload;
