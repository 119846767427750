import React, { useCallback, useEffect, useState } from "react";
import Page from "../../skeletons/Page";
import Button from "../../elements/atoms/Button";
import Panel from "../../elements/atoms/Panel";
import { AiOutlinePlus } from "react-icons/ai";
import useAxios from "../../../api/useAxios";
import { NavLink } from "react-router-dom";
import { useHashcatModes } from "../../elements/atoms/provider/HashcatModesProvider";

function Bounties() {
  const axios = useAxios();
  const [bounties, setBounties] = useState<any[]>([]);
  const { getMode } = useHashcatModes()

  const fetchBounties = useCallback(() => {
    (async function () {
      const resp = await axios.get("/v1/bounty/list");
      setBounties(resp.data);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBounties();
  }, [fetchBounties]);

  return (
    <Page full>
      <div className="w-full">
        <div className="flex h-full px-4 py-2 flex-col md:flex-row">
          <Panel className=" md:w-[300px]">
            Panel coming later
          </Panel>
          <Panel className="mt-4 md:mt-0 md:ml-4 flex-1 !p-0">
            <div className="flex items-center shadow-lg border-b border-b-gray-800 bg-black bg-opacity-30">
              <div className="flex-1 py-1 px-4 text-gray-600">Bounties</div>
              <div className="flex-0 p-2">
                <Button navlink="/new-bounty" type="outlined">
                  <AiOutlinePlus size={"16px"} />
                </Button>
              </div>
            </div>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {bounties.map((bounty) => {
                const { id, username, hashcatMode, count, reversed } = bounty;  
                const mode = getMode(hashcatMode);
                return (
                  <NavLink
                    key={id} 
                    to={`/bounty/${id}`}
                    className="bg-black shadow hover:scale-105 bg-opacity-40 rounded p-2 inline-block m-2 transition-all"
                  >
                    <div className="flex w-full">
                      <div className="font-bold flex-1">{mode.Name}</div>
                      <div className="text-gray-600">Remaining : {count - reversed}</div>
                    </div>
                    <div className="text-gray-800">By: {username}</div>
                  </NavLink>
                );
              })}
            </div>
          </Panel>
        </div>
      </div>
    </Page>
  );
}

export default Bounties;
