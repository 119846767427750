// AbstractInput.tsx
import React, { ChangeEvent } from "react";
import { useFormContext } from "../FormContext";

interface AbstractInputProps {
  name: string;
  label: string;
  type: string;
  readOnly?: boolean;
  defaultValue?: string;
  onChange?: (newValue: string) => void;
}

const AbstractInput: React.FC<AbstractInputProps> = ({
  name,
  label,
  type,
  defaultValue,
  readOnly, 
  onChange
}) => {
  const { values, errors, setValue } = useFormContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value);
    if(onChange){
      onChange(e.target.value)
    }
    // Call validation function if needed
  };
 
  return (
    <div className="block p-2 w-full text-left">
      <label
        className="px-2 text-sm opacity-60 group-focus:opacity-100"
        htmlFor={name}
      >
        {label}:
      </label>
      <input
        className="bg-gray-800 w-full text-gray-400 rounded outline-none py-1 px-2 "
        id={name}
        name={name}
        type={type}
        readOnly={readOnly}
        value={values[name] || defaultValue || ""}
        onChange={handleChange}
      />
      {errors[name] && (
        <div className="text-red-600 px-2 text-sm">{errors[name]}</div>
      )}
    </div>
  );
};

export default AbstractInput;
