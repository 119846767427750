import React from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "../../pages/Home";
import Login from "../../pages/account/Login";
import Register from "../../pages/account/Register";
import Activate from "../../pages/account/Activate";
import RegisterSuccess from "../../pages/account/RegisterSuccess";
import ActivateSuccess from "../../pages/account/ActivateSuccess";
import LoginSuccess from "../../pages/account/LoginSuccess";
import AuthGuard from "../atoms/auth/AuthGuard";
import Bounties from "../../pages/bounties/Bounties";
import NewBounty from "../../pages/bounties/NewBounty";
import Bounty from "../../pages/bounties/Bounty";
import Test from "../../pages/Test";

import Dashboard from "../../pages/app/Dashboard";
import Logout from "../../pages/account/Logout";
import Hashlists from "../../pages/app/Hashlists";
import Hashlist from "../../pages/app/Hashlist";
import Wordlists from "../../pages/app/Wordlists";
import Rulesets from "../../pages/app/Rulesets";

function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/account/login",
      element: <Login />,
    },
    {
      path: "/account/login-success",
      element: <LoginSuccess />,
    },
    {
      path: "/account/logout",
      element: <AuthGuard><Logout /></AuthGuard>,
    },      
    {
      path: "/account/register",
      element: <Register />,
    },   
    {
      path: "/account/register-success",
      element: <RegisterSuccess />,
    },
    {
      path: "/account/activate",
      element: <Activate />,
    },
    {
      path: "/account/activate-success",
      element: <ActivateSuccess />,
    },
    // ===== START APP =====
    { 
      path: "/app",
      element: <AuthGuard><Navigate to={"/app/dashboard"}/></AuthGuard>,
    },     
    { 
      path: "/app/dashboard",
      element: <AuthGuard><Dashboard /></AuthGuard>,
    },     
    { 
      path: "/app/hashlists",
      element: <AuthGuard><Hashlists /></AuthGuard>,
    },             
    { 
      path: "/app/hashlist/:id",
      element: <AuthGuard><Hashlist /></AuthGuard>,
    },             
    { 
      path: "/app/wordlists",
      element: <AuthGuard><Wordlists /></AuthGuard>,
    },         
    { 
      path: "/app/rulesets",
      element: <AuthGuard><Rulesets /></AuthGuard>,
    },             


    {
      path: "/bounties",
      element: <AuthGuard><Bounties /></AuthGuard>,
    },
    {
      path: "/new-bounty",
      element: <AuthGuard><NewBounty /></AuthGuard>,
    },
    {
      path: "/bounty/:id",
      element: <AuthGuard><Bounty /></AuthGuard>,
    },    
    {
      path: "/test",
      element: <AuthGuard><Test /></AuthGuard>,
    },
    {
      path: "*",
      element: <Home />,
    },
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default Router;
