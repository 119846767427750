import Page from "../../skeletons/App/Page";
import AddCreditTile from "./Tiles/AddCreditTile";
import BalanceTile from "./Tiles/BalanceTile";

function Dashboard() {
  return (
    <Page>
      <div className="grid md:grid-cols-2 lg:grid-cols-3">
        <BalanceTile className="m-2"/>
        <AddCreditTile className="m-2"/> 

      </div>
    </Page>
  );
}

export default Dashboard;
