import React from "react";

interface PanelProps {
  title?: string
    className?: string;
  children: React.ReactNode;
}

const Panel: React.FC<PanelProps> = (props) => {
  const { children, className, title } = props;

  return (
    <div className={[
        "p-4 bg-gray-900 border border-black inline-flex flex-col rounded-md shadow-md",
        className
    ].join(' ')}>
      {title && (<div className="bg-black text-left bg-opacity-10 -m-4 mb-4 py-2 px-6 font-bold rounded-t ">  
          {title}
        </div>)}
        <div className="items-center flex flex-1">
        <div className="w-full">
      {children}
      </div>
      </div>
    </div>
  );
};

export default Panel;
