import React, { useEffect, useState } from 'react';
import InputSelect from './InputSelect';
import { useHashcatModes } from '../../provider/HashcatModesProvider';
const InputHashcatMode: React.FC<{ name: string; label: string }> = ({ name, label }) => {
  
  const [options, setOptions] = useState<any[]>([])
  const { modes } = useHashcatModes()

  useEffect(() => {
    (async function() {
      if(options.length > 0){
        return
      }
      const opts = (modes).map((opt:any) => {
        return {
          value: opt.Mode,
          label: `${opt.Mode} - ${opt.Name}`
        }
      })
      setOptions(opts)
    })()
  })

  return (
    <InputSelect name={name} label={label} options={options}/>
  );
};

export default InputHashcatMode;
