import { useState } from "react";
import Panel from "../../../elements/atoms/Panel";
import { FormProvider } from "../../../elements/atoms/form/FormContext";
import Form from "../../../elements/atoms/form/Form";
import Button from "../../../elements/atoms/Button";
import InputText from "../../../elements/atoms/form/input/InputText";
import InputHashcatMode from "../../../elements/atoms/form/input/InputHashcatMode";
import InputTextArea from "../../../elements/atoms/form/input/InputTextArea";
import InputFile from "../../../elements/atoms/form/input/InputFile";
import * as yup from "yup";
import useAxios from "../../../../api/useAxios";

interface TileProps {
  className?: string;
  onAddSuccess?: () => void;
}

const AddHashlistTile: React.FC<TileProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const axios = useAxios();

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      setError(null);
      await axios.post("/v1/hashlist/new", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if(props.onAddSuccess){
        props.onAddSuccess()
      }
    } catch (e: any) {
      setError(e.response.data.error);
    }
    setLoading(false);
  };

  const validationSchema = yup
    .object()
    .shape({
      label: yup.string().required("Hashlist name is required"),
      mode: yup.string().required("Hashcat mode is required"),
      hashesText: yup.string().nullable(),
      hashesFile: yup.mixed().nullable(),
    })
    .test(
      "text-or-file-validation",
      'You must provide either "text" or "file", but not both.',
      function (values) {
        const { hashesText, hashesFile } = values;

        const hasText = Boolean(hashesText && hashesText.trim());
        const hasFile = Boolean(hashesFile);

        if (hasText && hasFile) {
          return new yup.ValidationError([
            new yup.ValidationError(
              "You must fill in only file or text, not both",
              null,
              "hashesText"
            ),
            new yup.ValidationError(
              "You must fill in only file or text, not both",
              null,
              "hashesFile"
            ),
          ]);
        }
        if (!hasText && !hasFile) {
          return new yup.ValidationError([
            new yup.ValidationError(
              "You must fill at least file or text",
              null,
              "hashesText"
            ),
            new yup.ValidationError(
              "You must fill at least file or text",
              null,
              "hashesFile"
            ),
          ]);
        }

        return true;
      }
    );

  return (
    <Panel
      title="New hashlist"
      className={["inline-block", props.className].join(" ")}
    >
      <FormProvider validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <InputText name="label" label="Hashlist name" />
          <InputHashcatMode name="mode" label="Hashcat Mode" />
          <InputTextArea
            className="font-mono"
            name="hashesText"
            label="Hashes (as text)"
          />
          <InputFile name="hashesFile" label="Hashes (or as a file)" />
          <div className="text-center">
          {error && <div className="text-red-600 m-4 font-bold">{error}</div>}
          {loading && <div className=" m-4 font-bold"> Creating hashlist ... </div>}            
          </div>
          <Button className="mt-4 w-full"> Create </Button>
        </Form>
      </FormProvider>
    </Panel>
  );
};

export default AddHashlistTile;
