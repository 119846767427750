import React from "react";
import Sidebar from "./Sidebar";
import { FaHashtag, FaExchangeAlt, FaList, FaCogs, FaCode} from 'react-icons/fa';
import Topbar from "./Topbar";
import MobileTopbar from "./MobileTopbar";
import { MENU_SEPARATOR } from "./MenuItem";
import NavLink from "../../elements/atoms/NavLink";
import { MdDashboard, MdLogout } from "react-icons/md";


interface PageProps {
  children: React.ReactNode; // children can be any valid React node
}

const Page: React.FC<PageProps> = ({ children }) => {
  const sidebarItems = [
    {label: "Dashboard", icon: <MdDashboard />, route: "/app/dashboard"},
    MENU_SEPARATOR,
    {label: "Hashlists", icon: <FaHashtag/>, route: "/app/hashlists"},
    {label: "Wordlists", icon: <FaList/>, route: "/app/wordlists"},
    {label: "Rulesets", icon: <FaCode/>, route: "/app/rulesets"},
    MENU_SEPARATOR,
    {label: "Transactions", icon: <FaExchangeAlt/>, route: "#"},
    {label: "Settings", icon: <FaCogs/>, route: "#"},
    MENU_SEPARATOR,
    {label: "Logout", icon: <MdLogout/>, route: "/account/logout"},

  ]

  return (
    <div className="h-[100vh] w-[100vw] overflow-hidden flex">
      {/* SIDEBAR */}
      <div className="p-4 pr-0 hidden md:block z-10">
        <Sidebar items={sidebarItems}/>
      </div>
      {/* RIGHT */}
      <div className="  flex-1">
        <div className="flex flex-col h-full w-full">
          {/* TOPBAR */}
          <div className=" p-4 pb-0 w-full hidden md:block">
            <div className="w-full h-full">
             <Topbar/>
            </div>
          </div>
          {/* MOBILE TOPBAR */}
          <div className=" p-4 pb-0 w-full visible md:hidden">
            <div className="w-full h-full">
             <MobileTopbar items={sidebarItems}/>
            </div>
          </div>          
          {/* CONTENT */}
          <div className="flex-1 p-4 h-[100px] w-full">
          <div className="w-full h-full text-gray-600 overflow-y-auto">
          { children }
          </div>
          </div>
          <div className="px-4 pb-6 text-center text-gray-400 text-xs">
          By using this platform, you acknowledge and agree to comply with our <NavLink to="#">Terms and Conditions</NavLink>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
