import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import Modal from "../modal/Modal";
import Panel from "../Panel";
import Button from "../Button";

interface ConfirmContextType {
  confirm: (message: string, subMessage?: string) => Promise<boolean>;
}

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmProvider");
  }
  return context.confirm;
};

export const ConfirmProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [subMessage, setSubMessage] = useState("");
  const [resolvePromise, setResolvePromise] =
    useState<(value: boolean) => void>();

  const confirm = useCallback(
    (message: string, subMessage: string = ""): Promise<boolean> => {
      setMessage(message);
      setSubMessage(subMessage);
      setIsOpen(true);

      return new Promise<boolean>((resolve) => {
        setResolvePromise(() => resolve);
      });
    },
    []
  );

  const handleConfirm = () => {
    if (resolvePromise) resolvePromise(true);
    setIsOpen(false);
  };

  const handleCancel = () => {
    if (resolvePromise) resolvePromise(false);
    setIsOpen(false);
  };

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      <Modal isOpened={isOpen} setIsOpened={handleCancel}>
        <Panel title="Confirm ?">
          <div>
            <div className="text-xl font-bold p-4">{message}</div>
            <div className="text-gray-600 text-sm -mt-5 mb-6 italic">{subMessage}</div>
            <div className="grid grid-cols-2">
              <Button onClick={handleCancel} type="outlined">
                Cancel
              </Button>
              <Button onClick={handleConfirm}>Confirm</Button>
            </div>
          </div>
        </Panel>
      </Modal>
    </ConfirmContext.Provider>
  );
};
