import { useLocation, useNavigate } from "react-router-dom";
import Page from "../../skeletons/Page";
import Highlight from "../../elements/atoms/Highlight";
import { useCallback, useEffect, useState } from "react";
import useAxios from "../../../api/useAxios";
 

function Activate() {
  const axios = useAxios()
  const location = useLocation();
  const navigate = useNavigate()
  const [error, setError] = useState();

  const token = new URLSearchParams(location.search).get('token');

  const activate = useCallback(async () => {
    setError(undefined);
    try {
      await axios.post("/v1/account/activate", {token});
      navigate("/account/activate-success")
    } catch (error: any) {
      setError(error.response.data.error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token])

  useEffect(() => {
    activate()
  }, [activate])

  if(token === undefined){
    navigate("/")
    return <div></div>
  }

  if(error){
    return (<Page>
      <div className="text-center w-full">
        <div className="text-3xl">
          <Highlight>Error activating your account</Highlight>
        </div>
        <p className="max-w-[90vw] m-auto mt-4 text-gray-400">
          {error} 
         </p>
      </div>
    </Page>)
  }

  return (
    <Page>
      <div className="text-center w-full">
        <div className="text-3xl">
          <Highlight>Activating account ... </Highlight>
        </div>
        <p className="max-w-[90vw] m-auto mt-4 text-gray-400">
          Please wait while your account is being activated, it can take up to 10 seconds.
         </p>
      </div>
    </Page>
  );
}

export default Activate;
