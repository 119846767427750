import React from "react";

// TypeScript type for the props of the TimeAgo component
interface TimeAgoProps {
  timestamp: string;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ timestamp }) => {
  // Function to calculate the time ago string
  const getTimeAgo = (date: string): string => {
    const now = new Date();
    const then = new Date(date);
    const difference = Math.floor((now.getTime() - then.getTime()) / 1000); // Difference in seconds

    if (difference < 60) {
      return `${difference} seconds ago`;
    } else if (difference < 3600) {
      return `${Math.floor(difference / 60)} minutes ago`;
    } else if (difference < 86400) {
      return `${Math.floor(difference / 3600)} hours ago`;
    } else {
      return `${Math.floor(difference / 86400)} days ago`;
    }
  };

  return <span>{getTimeAgo(timestamp)}</span>;
};

export default TimeAgo;
