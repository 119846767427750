// src/components/Topbar.tsx
import React, { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "./logo.png";
 import Highlight from "../../elements/atoms/Highlight";
import Button from "../../elements/atoms/Button";
import NavLink from "../../elements/atoms/NavLink";
import { NavLink as NL } from "react-router-dom";
import { useAuth } from "../../elements/atoms/auth/AuthContext";

const Topbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

 const { isLoggedIn } = useAuth()

 const [ links, setLinks ] = useState<any[]>([])

 useEffect(() => {
    if(isLoggedIn()){
        setLinks([
            { to: "/account/notfdound", label: "Tools" }, 

            { to: "/account/settings", label: "Account" }, 
            { to: "/app/dashboard", label: "Dashboard", style: 'outlined' },
        ]);
    } else {
        setLinks([
            { to: "/account/login", label: "Login" },
            { to: "/account/register", label: "Register", style: 'outlined' }
        ]);
    }

 }, [isLoggedIn])


  return (
    <header className="shadow-md border-b border-gray-800 bg-black text-white md:bg-transparent top-0 z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className=" flex  items-center font-bold brightness-0 invert">
            <NL to={"/"}>
          <img src={logo} alt="logo" className=" relative inline-block -my-12 h-14" />
          </NL>
          <Highlight>Hash Republic</Highlight>
        </div>
        {/* Desktop Menu */}
        <nav className="hidden md:flex items-center space-x-6">
          {links.map((link) => {
            if(link.style === 'outlined') {
                return <Button key={link.to} navlink={link.to} type="outlined">
                    {link.label}
                </Button>
            }
            return (
              <NavLink
                key={link.to}
                to={link.to}
              >
                {link.label}
              </NavLink>
            );
          })}
        </nav>
        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            aria-label="Toggle Menu"
            className="opacity-50 hover:opacity-70 active:opacity-100 focus:outline-none"
          >
            {menuOpen ? (
              <AiOutlineClose size={24} />
            ) : (
              <AiOutlineMenu size={24} />
            )}
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {menuOpen && (
        <nav className="md:hidden  shadow-md">
          <ul className="space-y-2 py-2 px-4">
            {links.map((link) => {
              return (
                <li key={link.to}>
                  <NavLink
                    to={link.to}
                  >
                    {link.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Topbar;
