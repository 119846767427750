import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import useAxios from "../../../../api/useAxios";

// Define the context structure
interface RulesetContextType {
  options: { label: string; value: string }[];
  refreshOptions: () => Promise<void>;
}

// Create the context
const RulesetContext = createContext<RulesetContextType | undefined>(undefined);

// Custom hook to use RulesetContext
export const useRuleset = () => {
  const context = useContext(RulesetContext);
  if (!context) {
    throw new Error("useRuleset must be used within a RulesetProvider");
  }
  return context;
};

// Helper function to check if a refresh is needed (24 hours or more)
const isRefreshNeeded = () => {
  const lastFetchTime = localStorage.getItem("lastFetchTimeRulesetOptions");
  if (!lastFetchTime) return true; // No timestamp means data should be fetched

  const currentTime = new Date().getTime();
  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  return currentTime - parseInt(lastFetchTime, 10) > oneDayInMs;
};

// Provider component
export const RulesetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const axios = useAxios()

  const fetchOptions = useCallback(async () => {
    try {
      const resp = await axios.get("/v1/ruleset/list");  
      const formattedOptions = resp.data.filter((wl:any) => {
        return wl.canUse
      }).map((item: any) => ({
        label: item.label,
        value: item.id
      }));

      // Cache in localStorage and update state
      localStorage.setItem("RulesetOptions", JSON.stringify(formattedOptions));
      localStorage.setItem("lastFetchTimeRulesetOptions", new Date().getTime().toString()); // Save the current time
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching Ruleset options:", error);
    }
  }, [axios]);

  // Function to refresh options
  const refreshOptions = async () => {
    await fetchOptions();
  };

  useEffect(() => {
    const cachedOptions = localStorage.getItem("RulesetOptions");
    const diff = (cachedOptions !== JSON.stringify(options))
    if (cachedOptions && !isRefreshNeeded()) {
      if(diff){
        setOptions(JSON.parse(cachedOptions)); // Use cached data if it's still valid
      }
    } else {
      fetchOptions(); // Fetch new data if the cache is outdated
    }
  }, [fetchOptions, options]);

  return (
    <RulesetContext.Provider value={{ options, refreshOptions }}>
      {children}
    </RulesetContext.Provider>
  );
};
