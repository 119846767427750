import TimeAgo from "../../../elements/atoms/TimeAgo";
import Progress from "../../../elements/atoms/Progress";
import PanelLink from "../../../elements/atoms/PanelLink";
import { useHashcatModes } from "../../../elements/atoms/provider/HashcatModesProvider";

interface TileProps {
  className?: string;
  hashlist: any;
}

const HashlistTile: React.FC<TileProps> = (props) => {
  const { hashlist } = props;
  const { modeAsString } = useHashcatModes()

  const prettyCount = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(hashlist.count);
  const prettyReversed = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(hashlist.reversed);
  const prettyProgress = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format((100 * hashlist.reversed) / hashlist.count);

  return (
    <PanelLink
    to={`/app/hashlist/${hashlist.id}`}
      title={`Hashlist "${hashlist.label}"`}
      className={["inline-block", props.className].join(" ")}
    >
          <div className="text-center font-bold -mb-4">
            {modeAsString(hashlist.hashcatMode)}
          </div>
          <div className="text-center text-gray-400 text-sm p-2">
            <TimeAgo timestamp={hashlist.updatedAt} />
          </div>
          <Progress total={hashlist.count} progress={hashlist.reversed} />
          <div className="text-xs text-right">
            {prettyReversed} / {prettyCount} ({prettyProgress}%)
          </div>

    </PanelLink>
  );
};

export default HashlistTile;
