import React, { useState } from "react";
import { useFormContext } from "../FormContext";

const InputBoolean: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  const { errors, setValue } = useFormContext();

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    const newValue = !isToggled;
    setIsToggled(newValue);
    setValue(name, newValue);
  };

  return (
    <div className="group block p-2 my-2 w-full text-left select-none">
      <div className="flex flex-row items-center">
        <div className="bg-gray-700  h-5 w-10 rounded-full relative overflow-hidden" onClick={handleToggle}>
          <div
            className={`absolute top-0 left-0 right-0 bottom-0 cursor-pointer `}
          >
            <div
              className={[
                `absolute top-0 left-0 right-0 bottom-0 bg-gray-400 transition-all`,
                isToggled ? "opacity-100" : "opacity-0",
              ].join(" ")}
            ></div>
            <span
              className={[
                `absolute left-0 top-0 h-5 w-5 transform rounded-full bg-gray-900 shadow-md transition-transform duration-300 ease-in-out`,
                isToggled ? "translate-x-5" : "",
              ].join(" ")}
            />
          </div>
        </div>
        <div className={[
          "ml-2 px-2 text-sm opacity-60 group-focus:opacity-100 cursor-pointer", 
          isToggled ? "text-gray-400" : "text-gray-600",
         ].join(' ')} onClick={handleToggle}>
          {label}
        </div>
      </div>

      {errors[name] && (
        <div className={[
          "text-red-600 px-2 text-sm", 
        ].join(' ')}>{errors[name]}</div>
      )}
    </div>
  );
};

export default InputBoolean;
