// useAxios.js
import { useRef } from "react";
import { useAuth } from "../components/elements/atoms/auth/AuthContext";
import { getApiClient } from "./apiClient";

// Custom hook to set up Axios interceptors
const useAxios = () => {
  const { accessToken, refreshAccessToken } = useAuth();
  const accessTokenRef = useRef(accessToken)

  const apiClient = getApiClient()

  apiClient.interceptors.request.use(
    (config) => {
      if (accessTokenRef.current) {
        config.headers["Authorization"] = `Bearer ${accessTokenRef.current}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const newToken = await refreshAccessToken();

          accessTokenRef.current = newToken
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

          return apiClient(originalRequest);
        } catch (err) {
          // Handle refresh token errors
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );

  return apiClient;
};

export default useAxios;
