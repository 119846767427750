import React from "react";
import UserWidget from "./UserWidget";

 
interface TopbarProps {
 }

const Topbar: React.FC<TopbarProps> = () => {
 
  return (
    <div className="bg-black p-4 text-white relative h-full rounded-lg shadow-lg">
      <div className="flex">
        <div className="flex-1">

        </div>
        <UserWidget/>
      </div>
    </div>
  );
};

export default Topbar;
