import React, { useEffect } from "react";
import { useFormContext } from "../FormContext";

 
const InputHidden: React.FC<{ name: string; value: string,  }> = ({
  name,
  value,
}) => {
  const { setValue, values } = useFormContext();

  useEffect(() => {
    if(values[name] !== value){
      setValue(name, value)
    }
  }, [name, setValue, value, values])
 
  return (
    <input type="hidden"/>
  );
};

export default InputHidden;
