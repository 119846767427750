import React, { useCallback, useEffect, useState } from "react";
import Page from "../../skeletons/Page";
import { useParams } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import Button from "../../elements/atoms/Button";
import Panel from "../../elements/atoms/Panel";
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi2";
import { AiOutlineQuestion, AiOutlineSearch } from "react-icons/ai";
import Highlight from "../../elements/atoms/Highlight";
import TimeAgo from "../../elements/atoms/TimeAgo";
import { MdOutlineRefresh } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import Modal from "../../elements/atoms/modal/Modal";
import { FormProvider } from "../../elements/atoms/form/FormContext";
import Form from "../../elements/atoms/form/Form";
import InputText from "../../elements/atoms/form/input/InputText";
import InputHidden from "../../elements/atoms/form/input/InputHidden";
import { useHashcatModes } from "../../elements/atoms/provider/HashcatModesProvider";

function Bounty() {
  const { id } = useParams();

  const axios = useAxios();
  const [bounty, setBounty] = useState<any>(null);
  const [lines, setLines] = useState<any>(null);
  const { getMode } = useHashcatModes()

  const [resolveLine, setResolveLine] = useState<any>()
  const [resolveModalOpened, setResolveModalOpened] = useState<boolean>(false)

  const getBountyData = useCallback(() => {
    (async function () {
      const resp = await axios.get(`/v1/bounty/${id}`);
      setBounty(resp.data.bounty);
      setLines(resp.data.lines);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadPotFile = useCallback(() => {
    if (bounty === null || lines === null) {
      return
    }

    const potfile = lines.filter((line:any) => {
      return line.reversed && line.unlocked
    }).map((line:any) => {
      return `${line.hash}:${line.plaintext}`
    }).join("\n")

    const blob = new Blob([potfile], { type: 'text/plain' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'hashes.pot';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

  }, [bounty, lines])

  const unlockAllReversed = useCallback(() => {
    alert("not impleted yet")
  }, [])


  // RESOLVER 
  const [resolverError, setResolverError] = useState<string|null>(null)
  const openResolverModal = useCallback((line: any) => {
    setResolveLine(line)
    setResolverError(null)
    setResolveModalOpened(true)
  }, [])

  const closeResolverModal = useCallback(() => {
    setResolveModalOpened(false)
  }, [])

  const resolveLineProcess = async (values: any) => {
    try {
      setResolverError(null)
      await axios.post(`/v1/bounty/line/${values.line}/resolve`, {
        plaintext: values.plaintext
      })
      closeResolverModal()
      getBountyData()
      alert('Successfully resolved')
    } catch (e: any) {
      setResolverError(e.response.data.error)
    }

  };



  useEffect(() => {
    getBountyData();
  }, [getBountyData]);

  if (bounty === null || lines === null) {
    return (
      <Page>
        <div className="text-center w-full">Now loading ...</div>
      </Page>
    );
  }

  const hashcatMode = getMode(bounty.hashcatMode)

  return (
    <Page>
      <div className="text-center w-full">
        <Panel className="m-4 inline-block text-left">
          <div className="flex">
            <div className="flex-1 p-2">
              <div>
              <Highlight className=" text-2xl  font-bold ">Bounty</Highlight>
              </div>
              <div className="p-2 text-sm">
                <ul>
                <li><span className="font-medium text-gray-600">Created by  :</span> {bounty.username} {bounty.isOwner ? '(you)' : ''} </li>
                <li><span className="font-medium text-gray-600">Hashcat Mode :</span> {hashcatMode.Name} - {hashcatMode.Mode} ({hashcatMode.Category})</li>
                <li><span className="font-medium text-gray-600">Created :</span> <TimeAgo timestamp={bounty.createdAt}/> </li>
                <li><span className="font-medium text-gray-600">Updated :</span> <TimeAgo timestamp={bounty.updatedAt}/> </li>
                <li><span className="font-medium text-gray-600"># of hashes :</span> {bounty.count}</li>
                <li><span className="font-medium text-gray-600"># of recovered :</span> {bounty.reversed}</li>
                </ul>
              </div>
            </div>
            <div className="p-2">
              <div className="m-1">
                <Button onClick={getBountyData} type="outlined" className="!px-2" title="Refresh data">
                  <MdOutlineRefresh className="w-6 h-6"/>
                </Button>
              </div>
              {bounty.isOwner ? (<div>
                <div className="m-1">
                <Button onClick={downloadPotFile} type="outlined" className="!px-2" title="Download potfile">
                  <HiOutlineDownload className="w-6 h-6"/>
                </Button>
              </div>
              <div className="m-1">
                <Button onClick={unlockAllReversed} type="outlined" className="!px-2" title="Unlock all reversed">
                  <HiOutlineLockOpen className="w-6 h-6"/>
                </Button>
              </div>
                </div>) : []}
            </div>
          </div>
          
          <div className="bg-black rounded p-2">
            <table>
              <tbody>
              {lines.map((line: any) => {
                return (
                  <tr className="font-mono" key={line.id}>
                    <td className="p-1 px-2">
                      {line.reversed ? (
                        <AiOutlineSearch className="w-5 h-5" />
                      ) : (
                        <AiOutlineQuestion className="w-5 h-5 text-gray-600 cursor-pointer" onClick={() => openResolverModal(line)}/>
                      )}
                    </td>
                    <td className="p-1 px-2">
                      {line.unlocked ? (
                        <HiOutlineLockOpen className="w-5 h-5" />
                      ) : (
                        <HiOutlineLockClosed className="w-5 h-5 text-gray-600" />
                      )}
                    </td>
                    <td className="p-1 px-2">{line.hash}</td>
                    {bounty.isOwner ? (<td className="p-1 px-2">{line.plaintext}</td>) : []}
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
         </Panel>
      </div>
      {resolveModalOpened && (
      <Modal isOpened={resolveModalOpened} setIsOpened={closeResolverModal}>
        <div className="p-2">
          <Highlight className="text-2xl"> Resolve </Highlight>
        </div>
        
        <FormProvider onSubmit={resolveLineProcess}>
          <Form>
          <InputText name="hashcatmode" label="Hashcat Mode" readOnly defaultValue={`${hashcatMode.Name} - ${hashcatMode.Mode} (${hashcatMode.Category})`}/>
          <InputText name="hash" label="Hash" readOnly defaultValue={`${resolveLine.hash}`}/>
          <InputText name="plaintext" label="Plaintext" defaultValue=""/>
          <InputHidden name="line" value={resolveLine.id}/>
          {resolverError && <div className="text-red-600 m-4 font-bold">{resolverError}</div>}

          <div className="p-2">
            <Button>Resolve</Button>
          </div>
          </Form>
        </FormProvider>
    
      </Modal>
      )}

    </Page>
  );
}

export default Bounty;
