import React from "react";
import Page from "../skeletons/Page";
import Highlight from "../elements/atoms/Highlight";
import Button from "../elements/atoms/Button";

function Home() {
  return (
    <Page>
      <div className="text-center w-full">
        <div className="text-3xl">
          Welcome to
          <Highlight>Hash Republic</Highlight>
        </div>
        <div className="text-2xl">
          The Ultimate Hash Reversing Solution
        </div>
        <p className="max-w-[90vw] m-auto mt-4 text-gray-400">
          Unlock unparalleled power with <Highlight>Hash Republic</Highlight>, the premier platform for hash cracking at scale. <br/>
          Effortlessly harness the combined strength of multiple GPUs, simplifying the distribution of Hashcat tasks. <br/> 
          <br/>
          Imagine using <Highlight>120x RTX 4090</Highlight> GPUs on a single project, <Highlight>drastically reducing hash reversing time</Highlight>. <br/>
          Gain exclusive access to <Highlight>tailored wordlists and rulesets</Highlight> designed to supercharge your cracking capabilities. <br/>
          Experience the future of hash reversing with <Highlight>unmatched efficiency and speed</Highlight>.
        </p>

        <Button
          navlink="/account/register"
          className="scale-150 mt-16 hover:scale-[175%] transition-all !text-opacity-100"
          type="outlined"
        >
          {" "}
          Join the Republic !
        </Button>
      </div>
    </Page>
  );
}

export default Home;
