import { useState } from "react";
import Form from "../../../elements/atoms/form/Form";
import { FormProvider } from "../../../elements/atoms/form/FormContext";
import InputText from "../../../elements/atoms/form/input/InputText";
import Panel from "../../../elements/atoms/Panel";
import Button from "../../../elements/atoms/Button";
import InputAttackMode from "../../../elements/atoms/form/input/InputAttackMode";
import { useToast } from "../../../elements/atoms/toast/ToastContext";
import InputWordlist from "../../../elements/atoms/form/input/InputWordlist";
import useAxios from "../../../../api/useAxios";
import InputRuleset from "../../../elements/atoms/form/input/InputRuleset";

interface TileProps {
  className?: string;
}

const NewJobTile: React.FC<TileProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const [attackMode, setAttackMode] = useState<string>("0")
  const { addToast }  = useToast()
  const axios = useAxios()


  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      setError(null);
      const resp = await axios.post('/v1/job/create', values)
      addToast(JSON.stringify(resp.data), "info", 10000)
    } catch (e: any) {
      setError(e.response.data.error);
    }
    setLoading(false);
  };
  return (
    <Panel title="Create a new job">
      <FormProvider onSubmit={onSubmit}>
        <Form>
          <InputAttackMode name="attack" label="Attack mode" defaultValue={attackMode} onChange={(newVal) => {
            setAttackMode(newVal)
          }}/>
          {["HRL"].includes(attackMode) && <div className="text-xs italic text-gray-300">
            Before running <b className="text-gray-500">Hash Republic Loop (HRL)</b>, ensure you have sufficient amount of already recovered potfile, a good starting point is at least 20%. <br/>
            Starting an HRL attack without sufficient reversed hashes will not be efficient. <br/>
            Once the HRL attack become inneficient, retrieving new hashes may make it efficient again.
            </div>} 
          {["0"].includes(attackMode) && <InputRuleset name="rules" label="Ruleset"/>} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs1" label="Custom charset 1" />} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs2" label="Custom charset 2" />} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs3" label="Custom charset 3" />} 
          {["3", "6", "7"].includes(attackMode) && <InputText name="cs4" label="Custom charset 4" />} 
          {["0", "1", "6"].includes(attackMode) && <InputWordlist name="arg1" label="Wordlist 1"/>} 
          {["3", "7"].includes(attackMode) && <InputText name="arg1" label="Mask 1" />} 
          {["6"].includes(attackMode) && <InputText name="arg2" label="Mask 2" />} 
          {["1", "7"].includes(attackMode) && <InputWordlist name="arg2" label="Wordlist 2"/>}           
          <div className="text-center">
          {error && <div className="text-red-600 m-4 font-bold">{error}</div>}
          {loading && <div className=" m-4 font-bold"> Creating hashlist ... </div>}            
          </div>
          <Button className="mt-4 w-full"> Create </Button>
        </Form>
      </FormProvider>    </Panel>

  );
};

export default NewJobTile;
