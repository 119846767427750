// Progress.tsx
import React from "react";

interface ProgressProps {
  total: number;
  progress: number;
}

const Progress: React.FC<ProgressProps> = ({ total, progress }) => {

  const percent = Math.max(1, 100 * progress / total)

  return <div className="relative p-1 w-full bg-gray-800">
    <div className={[
      "h-2 bg-green-600 opacity-60", 
    ].join(" ")}
    style={{
      width: `${percent}%`
    }}
    ></div>

  </div>;
};

export default Progress;
