import React, { useRef, useState } from "react";
import { useFormContext } from "../FormContext";
import Button from "../../Button";
import { FaRegTrashAlt } from "react-icons/fa";

function formatFileSize(bytes: number) {
  const units = ["bytes", "KB", "MB", "GB", "TB"];
  let index = 0;
  let size = bytes;

  // Convert the size to the appropriate unit
  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }

  // Format to two decimal places
  return `${size.toFixed(2)} ${units[index]}`;
}

const InputFile: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  const { unsetValue, setValue, errors } = useFormContext();

  const [fileName, setFileName] = useState<string>();
  const [fileSize, setFileSize] = useState<string>();
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFileName(file.name);
      setFileSize(formatFileSize(file.size));
      setValue(name, file);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setFileName(file.name);
      setFileSize(formatFileSize(file.size));
      setValue(name, file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const clickInputFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const clearFile = () => {
    setFileName(undefined);
    setFileSize(undefined);
    unsetValue(name);
  }

  return (
    <div className="block p-2 w-full text-left">
      <label
        className="px-2 text-sm opacity-60 group-focus:opacity-100"
        htmlFor={name}
      >
        {label}:
      </label>
      <div className="hidden">
        <input
          ref={inputRef}
          id={name}
          name={name}
          type="file"
          onChange={handleChange}
        />
      </div>
      <div
        className={`input-group bg-gray-800 w-full flex items-center rounded outline-none py-1 px-2 text-white border-2 ${
          isDragOver ? "border-blue-400" : "border-transparent"
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <Button preventDefault onClick={clickInputFile}>Browse</Button>
        <div className="whitespace-nowrap flex-1 px-2">
          {fileName === undefined && fileSize === undefined ? (
            <div className="text-sm text-gray-600">No file selected</div>
          ) : (
            <div>
              <div className="text-sm text-gray-400">{fileName}</div>
              <div className="text-xs text-gray-600">{fileSize}</div>
            </div>
          )}
        </div>
        {fileName === undefined && fileSize === undefined ? (
          <div></div>
        ) : (
          <div onClick={clearFile} className="text-gray-500 px-2 cursor-pointer select-none hover:text-gray-200">
            <FaRegTrashAlt size={16} />

          </div>
        )}
      </div>
      {errors[name] && (
        <div className="text-red-600 px-2 text-sm">{errors[name]}</div>
      )}
    </div>
  );
};

export default InputFile;
