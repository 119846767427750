import React from "react";
import { useFormContext } from "../FormContext";

const InputTextArea: React.FC<{
  name: string;
  label: string;
  className?: string;
  cols?: number;
  rows?: number;
}> = ({ name, label, className, cols, rows }) => {
  const { values, setValue, errors } = useFormContext();

  const tCols = cols || 64;
  const tRows = rows || 8;

  const handleChange = (e: any) => {
    setValue(name, e.target.value);
  };

  return (
    <div className="block p-2 w-full text-left">
      <label
        className="px-2 text-sm opacity-60 group-focus:opacity-100"
        htmlFor={name}
      >
        {label}:
      </label>
      <div>
        <textarea
          defaultValue={values[name] || ""}
          onChange={handleChange}
          className={[
            "bg-gray-800 w-full text-gray-400 rounded outline-none py-1 px-2",
            className
          ].join(' ')}
          cols={tCols}
          rows={tRows}
        />
      </div>
      {errors[name] && <div className="text-red-600 px-2 text-sm">{errors[name]}</div>}
    </div>
  );
};

export default InputTextArea;
