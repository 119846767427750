import Panel from "../../../elements/atoms/Panel";
import useDownload from "../../../../api/useDownload";
import { useCallback } from "react";
import { useConfirm } from "../../../elements/atoms/confirm/ConfirmContext";
import { useToast } from "../../../elements/atoms/toast/ToastContext";
import useAxios from "../../../../api/useAxios";
import { useWordlist } from "../../../elements/atoms/provider/WordlistProvider";

interface TileProps {
  className?: string;
  onUnlock?: () => void;
  wordlist: any;
}

const WordlistTile: React.FC<TileProps> = (props) => {
  const { wordlist, onUnlock } = props;

  const axios = useAxios()
  const { addToast, delToast }  = useToast()
  const confirm = useConfirm()
  const download = useDownload()
  const { refreshOptions } = useWordlist()

  const prettyCount = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(wordlist.count);



  const unlockWordlistUsage = useCallback(async (wordlist: any) => {
    const result = await confirm(`Are you sure you want to unlock "${wordlist.label}" wordlist USAGE feature ?`, `this action will cost you ${wordlist.usagePrice} HRC`)
    if (result) {
      const toastId = addToast("Unlocking wordlist usage", "info", 0);
      try {
         await axios.post(`/v1/wordlist/${wordlist.id}/unlock-usage`);
        delToast(toastId)
        addToast('Successfully unlocked wordlist usage!', 'success');
        refreshOptions()
        if(onUnlock){
          onUnlock()
        }
      } catch (error) {
        delToast(toastId)
        addToast("Error unlocking wordlist usage", "error");

      }
    } else {
      addToast('Unlock action cancelled!');
    }
  }, [addToast, axios, confirm, delToast, onUnlock, refreshOptions])


  const unlockWordlistDownload = useCallback(async (wordlist: any) => {
    const result = await confirm(`Are you sure you want to unlock "${wordlist.label}" wordlist DOWNLOAD feature ?`, `this action will cost you ${wordlist.downloadPrice} HRC`)
    if (result) {
      const toastId = addToast("Unlocking wordlist download", "info", 0);
      try {
         await axios.post(`/v1/wordlist/${wordlist.id}/unlock-download`);
        delToast(toastId)
        addToast('Successfully unlocked wordlist download!', 'success');
        refreshOptions()
        if(onUnlock){
          onUnlock()
        }
      } catch (error) {
        delToast(toastId)
        addToast("Error unlocking wordlist download", "error");
      }
    } else {
      addToast('Unlock action cancelled!');
    }
  }, [addToast, axios, confirm, delToast, onUnlock, refreshOptions])  

  return (
    <Panel
      title={`${wordlist.label}`}
      className={["inline-block", props.className].join(" ")}
    >
      <div className="text-center font-bold">{wordlist.description}</div>
      <div className="text-center text-gray-400 text-sm p-2">
        {prettyCount} lines
      </div>
      <div className="p-2">
        <ul>
          {!wordlist.canUse && <li className="text-yellow-600 text-xs">
            <span className="inline-block w-2 h-2 mr-2 rounded-full bg-yellow-600"></span>
            <span 
              onClick={() => unlockWordlistUsage(wordlist)}
              className="cursor-pointer inline-block opacity-50 hover:opacity-70 active:opacity-100" 
            > Unlock usage for {wordlist.usagePrice} HRC </span> 
          </li>}              
          {wordlist.canUse && <li className="text-green-600 text-xs">
            <span className="inline-block w-2 h-2 mr-2 rounded-full bg-green-600"></span>
            Allowed usage 
          </li>}    
          {!wordlist.canDownload && <li className="text-yellow-600 text-xs">
            <span className="inline-block w-2 h-2 mr-2 rounded-full bg-yellow-600"></span>
            <span 
              onClick={() => unlockWordlistDownload(wordlist)}
              className="cursor-pointer inline-block opacity-50 hover:opacity-70 active:opacity-100" 
            > Unlock download for {wordlist.downloadPrice} HRC </span> 
          </li>}                        
          {wordlist.canDownload && <li className="text-green-600 text-xs">
            <span className="inline-block w-2 h-2 mr-2 rounded-full bg-green-600"></span>
            Allowed <span 
              onClick={() => download(`/v1/wordlist/${wordlist.id}/download`, `wordlist.txt.gz`)}
              className="cursor-pointer inline-block opacity-50 hover:opacity-70 active:opacity-100" 
            > download </span> 
          </li>}              
        </ul>
      </div>


    </Panel>
  );
};

export default WordlistTile;
