import React from "react";
import { useToast } from "./ToastContext";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineInfoCircle,
  AiOutlineWarning,
} from "react-icons/ai";

interface ToastProps {
  id: string;
  type: string;
  children: React.ReactNode; // children can be any valid React node
}

const Toast: React.FC<ToastProps> = (props) => {
  const { id, type, children } = props;

  const { delToast } = useToast();

  let icon = <div></div>;
  switch (type) {
    case "info":
      icon = (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-blue-800 text-blue-200">
          <AiOutlineInfoCircle className="w-5 h-5" />
          <span className="sr-only">Info icon</span>
        </div>
      );
      break;
    case "success":
      icon = (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-green-800 text-green-200">
          <AiOutlineCheck className="w-5 h-5" />
          <span className="sr-only">Check icon</span>
        </div>
      );
      break;
    case "error":
    default:
      icon = (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-red-800 text-red-200">
          <AiOutlineWarning className="w-5 h-5" />
          <span className="sr-only">Warn icon</span>
        </div>
      );
      break;
  }

  return (
    <div
      className="animate-zoomIn pointer-events-auto flex mx-auto items-center w-full max-w-md p-4 mb-4  rounded-lg shadow-lg text-gray-400 bg-gray-800"
      role="alert"
    >
      {icon}
      <div className="ms-3 text-sm font-normal">{children}</div>
      <button
        type="button"
        onClick={() => {
          delToast(id);
        }}
        className="ms-auto -mx-1.5 -my-1.5  rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5  inline-flex items-center justify-center h-8 w-8 text-gray-500 hover:text-white bg-gray-800 hover:bg-gray-700"
        data-dismiss-target="#toast-success"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <AiOutlineClose className="w-3 h-3" />
      </button>
    </div>
  );
};

export default Toast;
