import { useEffect, useState } from "react";
import useAxios from "../../../api/useAxios";
import Placeholder from "../../elements/atoms/Placeholder";

interface TileProps {
  className?: string;
}

const UserWidget: React.FC<TileProps> = (props) => {
  const axios = useAxios();
  const [details, setDetails] = useState<any>(null);
  const [initialized, setInitialized] = useState<boolean>(false)

  useEffect(() => {
    if(initialized){
        return
    }
    (async function () {
      setInitialized(true)
      // await new Promise((r) => setTimeout(r, 10000))
      const resp = await axios.get("/v1/account/details");
      setDetails(resp.data);
    })();
  });
 
  if(details === null){
    return (
        <div className={["inline-block", props.className].join(" ")}>
          <div className="text-right">
            <div className="font-bold"><Placeholder/></div>
            <div className="text-sm -mt-1"><Placeholder className="w-[120px]"/></div>
          </div>
        </div>
      );
  }

  return (
    <div className={["inline-block", props.className].join(" ")}>
      <div className="text-right">
        <div>
          <span className="font-bold">{details.username}</span>
        </div>
        <div className="text-sm -mt-1">
            {details.balance} HRC 
        </div>
      </div>
    </div>
  );
};

export default UserWidget;
