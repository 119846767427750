import React, { useState } from "react";
import UserWidget from "./UserWidget";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { MENU_SEPARATOR, MenuItem } from "./MenuItem";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { NavLink, useLocation } from "react-router-dom";
import logo from "./logo.png"

interface MobileTopbarProps {
  items: (MenuItem | string)[];
}

const MobileTopbar: React.FC<MobileTopbarProps> = ({ items }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="bg-gray-950 p-4 text-white relative h-full rounded-lg shadow-lg">
      <div className="flex">
          <div className="text-center flex flex-1 items-center whitespace-nowrap brightness-0 invert">
            <div className="h-8 overflow-hidden ">
            <img src={logo} className="w-16 h-16 -mt-3.5  inline-block" alt="logo" />
            </div>
            <span className="font-bold">Hash Republic</span>
          </div>
        <UserWidget />

        <button
          onClick={toggleMenu}
          aria-label="Toggle Menu"
          className="text-white ml-4 mr-2 opacity-50 hover:opacity-70 active:opacity-100 focus:outline-none"
        >
          {menuOpen ? (
            <AiOutlineClose size={24} />
          ) : (
            <AiOutlineMenu size={24} />
          )}
        </button>
      </div>
      {menuOpen ? (
        <div className="absolute z-[1000] text-right top-[100%] left-0 right-0  pt-2">
          <div className="bg-gray-900 p-4 text-white relative h-full rounded-lg shadow-lg">
            <div className="px-5 flex-1 relative">
              {items.map((item) => {
                if (typeof item === "string") {
                  if (item === MENU_SEPARATOR) {
                    return (
                      <div className="border-b border-transparent my-2"></div>
                    );
                  }
                  return [];
                }
                const isActive = item.route.includes(location.pathname.replace(/\/\d+/g, ""))
                return (
                  <NavLink
                    to={item.route}
                    title={item.label}
                    key={uuidv4()}
                    className={[
                      " opacity-30 hover:opacity-100 hover:bg-gray-700 hover:text-white p-1 rounded-lg block flex  items-center my-2 ",
                      (isActive ? 'bg-black text-white' : 'bg-white  bg-opacity-10')
                    ].join(" ")}
                  >
                    <div className="font-medium pl-2"> {item.label} </div>
                  </NavLink>
                );
              })}
            </div>
            <div className="p-4 text-center text-xs text-gray-300">
              {"<GITCOMMITHASH>".substring(0,8)}
              </div>
          </div>
        </div>
      ) : (
        []
      )}
    </div>
  );
};

export default MobileTopbar;
