import React, { useEffect, useState } from "react";
import { useFormContext } from "../FormContext";
import Select, { StylesConfig } from "react-select";

const InputSelect: React.FC<{
  name: string;
  label: string;
  defaultValue?: string;
  onChange?: (newValue: string) => void;
  options: {
    value: string;
    label: string;
  }[];
}> = ({ name, label, options, onChange, defaultValue }) => {
  const { setValue, errors } = useFormContext();

  const [defaultValueSet, togglerDefaultValueSet] = useState(false)

  useEffect(() => {
    if(defaultValueSet){
      return
    }
    if(defaultValue){
      setValue(name, defaultValue)
      if(onChange){
        onChange(defaultValue)
      }
      togglerDefaultValueSet(true)
    }
  }, [defaultValue, defaultValueSet, name, onChange, setValue])

  const handleChange = (e: any) => {
    setValue(name, e.value);
    if (onChange) {
      onChange(e.value);
    }
  };
  

  const customStyles: StylesConfig = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(31,41,55)",
      border: "transparent",
      boxShadow: "none",
      height: "32px", // Set height of the select control
      minHeight: "32px", // Ensure minimum height is 32px
    }),
    valueContainer: (provided) => ({
      ...provided,

      height: "100%", // Make the value container fill the control height
      padding: "0 8px", // Adjust padding if needed
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: "0", // Remove margin if needed
      color: "rgb(156 163 175)", // Customize color of the selected value
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "100%", // Make sure indicators container fills the height
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }), // Ensure dropdown is above the modal
    menu: (provided) => ({
      ...provided,
      padding: "8px 0", // Adjust padding if needed
      backgroundColor: "rgb(31,41,55)", // Set the background color of the dropdown menu
      zIndex: 200
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "rgba(255,255,255,0.6)"
        : state.isFocused
        ? "rgba(255,255,255,0.2)"
        : "", // Hover color
      color: state.isSelected ? "#fff" : "#fff", // Text color
      cursor: "pointer",
    }),
  };

  return (
    <div className="block p-2 w-full text-left">
      <label
        className="px-2 text-sm opacity-60 group-focus:opacity-100"
        htmlFor={name}
      >
        {label}:
      </label>
      <Select
        defaultValue={defaultValue && options.find((o) => o.value === defaultValue)}
        options={options}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        styles={customStyles}
      />
      {errors[name] && (
        <div className="text-red-600 px-2 text-sm">{errors[name]}</div>
      )}
    </div>
  );
};

export default InputSelect;
