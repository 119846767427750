import React from "react";
import Placeholder from "./Placeholder";

// TypeScript type for the props of the PanelPlaceholder component
interface PanelPlaceholderProps {
  className?: string;
}

const PanelPlaceholder: React.FC<PanelPlaceholderProps> = ({ className }) => {
  return (
    <div
      className={[
        "p-4 bg-gray-900 border border-black  inline-flex flex-col opacity-50 rounded-md shadow-md",
        className,
      ].join(" ")}
    >
      <div className="bg-black text-left bg-opacity-10 -m-4 mb-4 py-2 px-6 font-bold rounded-t ">
        <Placeholder />
      </div>
      <div className="items-center flex flex-1">
        <div className="w-full">
          <div className="w-full">
          <Placeholder className="w-[60%]"/>
          <Placeholder className="w-[85%]"/>
          <Placeholder className="w-[70%]"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelPlaceholder;
