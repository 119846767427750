import React, { useEffect, useState } from "react";
import Page from "../../skeletons/Page"; 
import Highlight from "../../elements/atoms/Highlight";
import { NavLink, useNavigate } from "react-router-dom";
function LoginSuccess() {

  const navigate = useNavigate()
  const [remainingSeconds, setRemainingSeconds] = useState(15)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(remainingSeconds > 0){
        setRemainingSeconds(remainingSeconds - 1)
      } else {
        clearInterval(intervalId)
        navigate("/")
      }
    }, 1000)

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate, remainingSeconds]); 

  return (
    <Page>
      <div className="text-center w-full">
        <div className="text-3xl">
          <Highlight>Successfully logged-in!</Highlight>
        </div>
        <p className="max-w-[90vw] m-auto mt-4 text-gray-400">
          You'll be redirected to homepage in {remainingSeconds} seconds ! 
          or <NavLink className="underline font-medium " to="/">click here</NavLink> to go now.
        </p>
      </div>
    </Page>
  );
}

export default LoginSuccess;
