import React, { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import logo from "./logo.png";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import { MENU_SEPARATOR, MenuItem } from "./MenuItem";


interface SidebarProps {
  items: (MenuItem | string)[];
}

const Sidebar: React.FC<SidebarProps> = ({ items }) => {
  const [mode, setMode] = useState<"compact" | "full">("compact");
  const location = useLocation();

  const toggleMode = useCallback(() => {
    if (mode === "compact") {
      setMode("full");
    } else {
      setMode("compact");
    }
  }, [mode]);

  return (
    <div className="bg-gray-950 border border-black relative h-full text-white flex flex-col z-0  rounded-lg shadow-lg">
       <div className="absolute opacity-50 top-0 left-0 h-full w-full bg-gray-900 rounded-lg">
        <div className="absolute top-0 left-0 h-full w-full rounded-lg from-10% from-[#ff4200] via-[#ba00b6] via-70% bg-gradient-to-tr mix-blend-overlay"></div>
      </div>      
      <div className="p-4 text-center flex items-center whitespace-nowrap brightness-0 invert">
        <img src={logo} className="w-16 h-16 inline-block" alt="logo" />
        <span
          className={["text-lg pr-4", mode === "full" ? "" : "hidden"].join(" ")}
        >
          Hash Republic{" "}
        </span>
      </div>

      <div className="px-5 flex-1 z-10 relative">
        {items.map((item) => {
          if (typeof item === "string"){
            if (item === MENU_SEPARATOR){
              return <div key={uuidv4()} className="border-b border-[#444444] my-6">
                
                </div>
            }
            return []
          }
          const isActive = item.route.includes(location.pathname.replace(/\/\d+/g, ""))
          return (
            <NavLink to={item.route} title={item.label} key={uuidv4()} className={[
              "bg-white  opacity-60 hover:opacity-100 p-2 rounded-lg block flex relative items-center my-2 ",
              isActive ? "opacity-80 bg-opacity-20" : "opacity-60 bg-opacity-10"
              ].join(" ")}>
                {isActive && (<div className="absolute left-0 top-0 bottom-0 w-1 bg-white rounded-l">
                </div>)}
                <div className="px-2"> {React.cloneElement(item.icon, { className: "w-[18px] h-[18px] m-[3px]" })} </div>
                <div
                  className={[
                    "font-medium pl-2",
                    "", mode === "full" ? "" : "hidden"
                    ].join(" ")}
                > {item.label} </div>
            </NavLink>
          );
        })}
      </div>
      
      <div 
        className={[
          "relative p-4 text-center text-sm text-gray-600",
          "", mode === "full" ? "" : "hidden"
          ].join(" ")}
      >
        {"<GITCOMMITHASH>".substring(0,8)}
      </div>

      <div className="absolute bottom-24 right-0 z-50" onClick={toggleMode}>
        <div className="rounded-full bg-black p-1.5 translate-x-[50%] cursor-pointer border-2 border-[#091028]">
          {mode === "full" ? <IoIosArrowBack className="w-3 h-3"/> : <IoIosArrowForward className="w-3 h-3"/>}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
