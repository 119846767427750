import React from "react";
import Page from "../../skeletons/Page"; 
import Highlight from "../../elements/atoms/Highlight";
function RegisterSuccess() {
  
  return (
    <Page>
      <div className="text-center w-full">
        <div className="text-3xl">
          <Highlight>Registration complete!</Highlight>
        </div>
        <p className="max-w-[90vw] m-auto mt-4 text-gray-400">
          We are proud you joined the Republic! But before starting enjoying this new world, you need to activate your account. <br/>
          Don't wait more, check your emails and click on the activation link!
        </p>
      </div>
    </Page>
  );
}

export default RegisterSuccess;
