import { useEffect, useState } from "react";
import useAxios from "../../../../api/useAxios";
import Panel from "../../../elements/atoms/Panel";

interface TileProps {
  className?: string;
}

function formatNumber(value: number): string {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

const BalanceTile: React.FC<TileProps> = (props) => {
  const axios = useAxios();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    (async function () {
      const resp = await axios.get("/v1/account/details");
      setBalance(resp.data.balance);
    })();
  });

  return (
    <Panel title="Account balance" className={["inline-block", props.className].join(" ")}>
      
        <div className="text-3xl text-center">
          { formatNumber(balance) } 
          &nbsp;<span className="text-xs">HRC</span>
        </div>
      
    </Panel>
  );
};

export default BalanceTile;
