import React from "react";
import { NavLink } from "react-router-dom";

interface PanelLinkProps {
  to: string;
  title?: string
  className?: string;
  children: React.ReactNode;
}

const PanelLink: React.FC<PanelLinkProps> = (props) => {
  const { children, className, title, to } = props;

  return (
    <NavLink to={to} className={[
        "p-4 bg-gray-900 border border-black inline-flex flex-col rounded-md shadow-md",
        "hover:scale-[1.05] transition-all",
        className
    ].join(' ')}>
      {title && (<div className="bg-black text-left bg-opacity-10 -m-4 mb-4 py-2 px-6 font-bold rounded-t ">  
          {title}
        </div>)}
        <div className="items-center flex flex-1">
        <div className="w-full">
      {children}
      </div>
      </div>
    </NavLink>
  );
};

export default PanelLink;
